<template>
  <div class="account">
    <v-snackbar v-model="snackbar_update" :timeout="3000" top color="success">
      <span>Update successful.</span>
      <v-btn @click="snackbar_update = false" text dark>Close</v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbar_reset" :timeout="4000" top color="success">
      <span>Success! Please check your email to change your password.</span>
      <v-btn @click="snackbar_reset = false" text dark>Close</v-btn>
    </v-snackbar>
    <h1 class="mt-6 text-center">My Account</h1>
    <v-container style="max-width:600px">
      <v-row>
        <v-col>
          <v-card class="mx-3 px-2" style="max-width:600px" :loading="isLoading">
            <v-card-title>Personal Information</v-card-title>
            <v-row>
              <v-col sm="5" md="4">
                <v-card-text class="text-center">
                  <v-avatar size="100">
                    <v-img v-if="!avatarURL" src="@/assets/images/blank-profile-picture_100px.png" />
                    <v-img v-else :src="avatarURL" />
                  </v-avatar>
                  <v-btn text color="primary" @click="onPickFile">
                    <input
                      type="file"
                      style="display: none"
                      ref="fileInput"
                      accept="image/png, image/jpeg, image/bmp"
                      @change="onFilePicked"
                    />
                    <v-icon>edit</v-icon>Edit Photo
                  </v-btn>
                </v-card-text>
              </v-col>
              <v-col sm="6" md="8">
                <v-form ref="personalInfo">
                  <v-card-text>
                    <v-form ref="form">
                      <v-text-field
                        label="First Name"
                        type="text"
                        :error-messages="error"
                        v-model="firstName"
                        required
                      />
                      <v-text-field
                        label="Last Name"
                        type="text"
                        :error-messages="error"
                        v-model="lastName"
                        required
                      />
                      <v-select
                        v-model="userAge"
                        :items="ageRanges"
                        item-text="text"
                        item-value="value"
                        label="Age"
                      />
                      <v-card-actions>
                        <v-spacer />

                        <v-btn
                          class="primary"
                          @click="personalUpdate"
                          :disabled="!personalChanged"
                          :loading="isLoading"
                        >
                          <v-icon>save</v-icon>Save Changes
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card-text>
                </v-form>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="mx-3 px-2" style="max-width:600px">
            <v-card-title>Reset Password</v-card-title>
            <v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="resetDialog = true">
                  <v-icon>email</v-icon>Send email with my reset link
                </v-btn>
                <v-dialog v-model="resetDialog" max-width="290">
                  <v-card>
                    <v-card-title class="headline">Reset Password?</v-card-title>

                    <v-card-text>Are you sure you want to reset your password? You will receive an email with a link to rest your password.</v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn color="primary" text @click="resetDialog = false">Cancel</v-btn>

                      <v-btn color="primary" text @click="resetPass">Yes, Reset</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Account",
  mixins: [validationMixin],

  // TODO: Check that errors display correctly (Vuex or local) for name Errors and validation
  validations: {
    // firstName: { required, minLength: minLength(1) },
    // lastName: { required, minLength: minLength(1) }
  },
  data: function() {
    return {
      ageRanges: [
        { value: "<20", text: "Under 20" },
        { value: "20-29", text: "20-29" },
        { value: "30-39", text: "30-39" },
        { value: "40-49", text: "40-49" },
        { value: "50-59", text: "50-59" },
        { value: "60+", text: "Over 60" }
      ],
      snackbar_update: false,
      updateFirst: null,
      updateLast: null,
      updateAge: null,
      updateAvatarURL: null,
      resetDialog: false,
      snackbar_reset: false
    };
  },
  computed: {
    ...mapGetters(["isLoading", "error"]),
    // These two computed functions check for any updates and then activate the Save Changes button
    personalChanged() {
      if (
        this.updateFirst !== null &&
        this.updateFirst !== this.$store.getters.firstName
      ) {
        return true;
      } else if (
        this.updateLast !== null &&
        this.updateLast !== this.$store.getters.lastName
      ) {
        return true;
      } else if (
        this.updateAge !== null &&
        this.updateAge !== this.$store.getters.userAge
      ) {
        return true;
      } else {
        return false;
      }
    },
    firstName: {
      get() {
        return this.$store.getters.firstName;
      },
      set(value) {
        this.updateFirst = value;
      }
    },
    lastName: {
      get() {
        return this.$store.getters.lastName;
      },
      set(value) {
        this.updateLast = value;
      }
    },
    userAge: {
      get() {
        return this.$store.getters.userAge;
      },
      set(value) {
        this.updateAge = value;
      }
    },
    avatarURL: {
      get() {
        return this.$store.getters.avatarURL;
      }
    }
  },

  methods: {
    onFilePicked() {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file extension.");
      }
      if (files[0].size >= 1024000) {
        return alert("Please select an image under 1MB in size.");
      }
      this.$store.dispatch("updateAvatarURL", files[0]);
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    personalUpdate(e) {
      let userProfile = {};

      if (this.updateFirst !== null) {
        userProfile.firstName = this.updateFirst;
      }
      if (this.updateLast !== null) {
        userProfile.lastName = this.updateLast;
      }
      if (this.updateAge !== null) {
        userProfile.userAge = this.updateAge;
      }

      // Commit all changes to Firestore at the same time to prevent 1 update per second limit
      this.$store.dispatch("updateUserProfile", userProfile);

      // TODO: return errors if it fails to update, based on db response
      this.snackbar_update = true;

      // Reset temporary form states to blank
      this.updateFirst = null;
      this.updateLast = null;
      this.updateAge = null;
    },
    resetPass() {
      this.$store.dispatch("resetPass", this.$store.getters.email);
      this.resetDialog = false;
      this.snackbar_reset = true;
      //Should I log them out after?
    }
  }
};
</script>
